
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
import { useRoute } from 'vue-router'
import { goUrl } from '@/utils/index'
import {
  get
} from '@/service/ajax'

// 使用接口获取数据

@Options({
  data () {
    return {
      activeIndex: '',
      list_id: '',
      info_id: '',
      lists_obj: {},
      info_obj: {},

      page_size: 9,
      activePage: 1,
      products_arr: [],
      info_info: {},
      menu_name: ''
    }
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.product,
      language: (state:any) => state.language
    })
  },
  updated () {
    // console.log('========= updated =========')
    this.infoInit()
    // console.log('========= end =========')
  },
  mounted (): void {
    console.log('========= mounted =========')
    this.infoInit()
    // console.log('========= end =========')
  },
  methods: {
    infoInit () {
      const route = useRoute()
      // console.log('infoInit route ====', route)
      // console.log('infoInit route.params ====', route.params)
      // console.log('infoInit route.fullPath ====', route.fullPath)
      // console.log('infoInit route.query ====', route.query)
      // console.log('infoInit this.json ====', this.json)
      if (route.params.listid && route.params.infoid && route.params.infoid !== this.activeIndex) {
        this.list_id = route.params.listid
        this.activePage = route.params.page
        this.activeIndex = route.params.infoid
        this.getProductList()
        this.getProductsArr()
      }
      this.getInfo()
    },
    // 获取菜单列表
    getProductList () {
      // type: 1.中文 2.阿拉伯文
      const languageType = this.language === 'cn' ? 1 : 2
      // 第一个参数groupType:1.产品2.工程
      get('/queryGroup/2/' + languageType, '').then((res:any) => {
        // console.log('getProductList res ====', res)
        if (res.rows.length > 0) {
          res.rows.forEach((item:any, index:any) => {
            if (item.id * 1 === this.list_id * 1) {
              this.menu_name = item.name
            }
          })
        }
      })
    },
    // 获取商品列表
    getProductsArr () {
      // console.log('productInit products_obj ====', this.products_obj)
      this.products_arr = []
      const languageType = this.language === 'cn' ? 1 : 2
      get(`/queryProjectCase/${this.list_id}/${languageType}?pageNum=${this.activePage}&pageSize=${this.page_size}`, '').then((res:any) => {
        // console.log('getProductsArr res ====', res)
        if (res.rows.length > 0) {
          this.products_arr = res.rows.map((item:any, index:any) => {
            item.id = item.id.toString()
            return item
          })
        }
      })
    },
    getInfo () {
      const languageType = this.language === 'cn' ? 1 : 2
      get(`/getProjectCaseInfo/${this.activeIndex}/${languageType}`, '').then((res:any) => {
        // console.log('getInfo res ====', res)
        this.info_info = res.data || {}
      })
    },
    handleSelect (key: string, keyPath: string[], item:any) {
      // console.log('========= handleSelect =========')
      // key === 3
      // console.log('key ===', key)
      // console.log('keyPath ===', keyPath)
      // console.log('item ===', item)
      // console.log('this.activeIndex ===', this.activeIndex)
      if (key !== this.activeIndex) {
        this.activeIndex = key
        const url = '/info/' + this.list_id + '/' + this.activePage + '/' + key
        goUrl(url)
      }
      // console.log('========= end =========')
    },
    goBack () {
      // console.log('========= goBack =========')
      // const router = useRouter()
      // console.log('useRouter ===', useRouter)
      // console.log('router ===', router)
      // console.log('this.$router ===', this.$router)
      // router.go(-1)
      this.$router.back()
      // console.log('========= end =========')
    }
  }
})
export default class Info extends Vue {}
