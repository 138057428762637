
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
import { useRoute } from 'vue-router'
import { goUrl } from '@/utils/index'
import {
  get
} from '@/service/ajax'

@Options({
  data () {
    return {
      activeIndex: '',
      page_size: 9,
      activePage: 1,
      products_arr: [],
      srcList: [],
      products_menus: [],

      total: 0
    }
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.product,
      language: (state:any) => state.language
    })
  },
  updated () {
    // console.log('========= updated =========')
    this.productInit()
    // console.log('========= end =========')
  },
  mounted (): void {
    // console.log('========= mounted =========')
    this.productInit()
    // console.log('========= end =========')
  },
  methods: {
    // 初始化
    productInit () {
      const route = useRoute()
      // console.log('productInit route ====', route)
      // console.log('productInit route.params ====', route.params)
      // console.log('productInit route.fullPath ====', route.fullPath)
      // console.log('productInit route.query ====', route.query)
      // console.log('productInit this.json ====', this.json)
      this.activePage = 1
      this.activeIndex = route.params.productid
      this.getMenuList()
      // this.getMenuList()
    },
    // 获取菜单列表
    getMenuList () {
      // type: 1.中文 2.阿拉伯文
      const languageType = this.language === 'cn' ? 1 : 2
      // 第一个参数groupType:1.产品2.工程
      get('/queryGroup/1/' + languageType, '').then((res:any) => {
        // console.log('getMenuList res ====', res)
        if (res.rows.length > 0) {
          this.products_menus = res.rows.map((item:any, index:any) => {
            item.id = item.id.toString()
            return item
          })
          if (this.activeIndex * 1 === 0) {
            this.activeIndex = this.products_menus[0].id
          }
          // console.log('products_menus ====', this.products_menus)
          this.getProductsArr()
        }
      })
    },
    // 获取商品列表
    getProductsArr () {
      this.products_arr = []
      const languageType = this.language === 'cn' ? 1 : 2
      get(`/queryProduct/${this.activeIndex}/${languageType}?pageNum=${this.activePage}&pageSize=${this.page_size}`, '').then((res:any) => {
        // console.log('getProductsArr res ====', res)
        this.products_arr = res.rows
        this.total = res.total * 1
        // console.log('total ====', this.total)
      })
    },
    handleSelect (key: string, keyPath: string[], item:any) {
      console.log('========= handleSelect =========')
      // key === /product/2
      // console.log('key ===', key)
      // console.log('keyPath ===', keyPath)
      // console.log('item ===', item)
      // console.log('this.activeIndex ===', this.activeIndex)
      // console.log('========= end =========')
      if (key !== this.activeIndex) {
        this.activeIndex = key
        goUrl(key)
      }
    },
    goInfo (activity:any) {
      // console.log('goInfo activity ====', activity)
      // console.log('goInfo this.products_menus ====', this.products_menus)
      // console.log('goInfo this.activeIndex ====', this.activeIndex)
      if (activity.id) {
        const url = '/detail/' + this.activeIndex + '/' + this.activePage + '/' + activity.id
        goUrl(url)
      }
    },
    // loadProduct () {
    //   // console.log('======== loadProduct ========')
    //   // if (true) {
    //   this.getProductsArr()
    //   // }
    // },
    handleCurrentChange (val: number) {
      console.log(`current page: ${val}`)
      this.activePage = val
      this.getProductsArr()
    },
    viewImage (val: string) {
      this.srcList = []
      this.srcList.push(val)
    }
  }
})
export default class Product extends Vue {}
