
import { Options, Vue } from 'vue-class-component'
import Layheader from '@/components/layout/layheader.vue'
import LayFooter from '@/components/layout/layfooter.vue'

@Options({
  components: {
    Layheader,
    LayFooter
  }
})
export default class LayOut extends Vue {}
