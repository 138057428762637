
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
import { useRoute } from 'vue-router'
import { goUrl } from '@/utils/index'
import {
  get
} from '@/service/ajax'

@Options({
  data () {
    return {
      activeIndex: '',
      page_size: 9,
      activePage: 1,

      list_menus: [],
      lists_arr: []
    }
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.list,
      language: (state:any) => state.language
    })
  },
  updated () {
    // console.log('========= updated =========')
    this.listInit()
    // console.log('========= end =========')
  },
  mounted (): void {
    // console.log('========= mounted =========')
    this.listInit()
    // console.log('========= end =========')
  },
  methods: {
    // 初始化
    listInit () {
      const route = useRoute()
      // console.log('listInit route ====', route)
      // console.log('listInit route.params ====', route.params)
      // console.log('listInit route.fullPath ====', route.fullPath)
      // console.log('listInit route.query ====', route.query)
      // console.log('listInit this.json ====', this.json)

      this.activePage = 1
      this.activeIndex = route.params.listid
      this.getMenuList()

      // console.log('listInit json ====', this.json)
    },
    // 获取菜单列表
    getMenuList () {
      // type: 1.中文 2.阿拉伯文
      const languageType = this.language === 'cn' ? 1 : 2
      // 第一个参数groupType:1.产品2.工程
      get('/queryGroup/2/' + languageType, '').then((res:any) => {
        // console.log('getMenuList res ====', res)
        if (res.rows.length > 0) {
          this.list_menus = res.rows.map((item:any, index:any) => {
            item.id = item.id.toString()
            return item
          })
          if (this.activeIndex * 1 === 0) {
            this.activeIndex = this.list_menus[0].id
          }
          // console.log('list_menus ====', this.list_menus)
          this.getListsArr()
        }
      })
    },
    // 获取商品列表
    getListsArr () {
      this.products_arr = []
      const languageType = this.language === 'cn' ? 1 : 2
      get(`/queryProjectCase/${this.activeIndex}/${languageType}?pageNum=${this.activePage}&pageSize=${this.page_size}`, '').then((res:any) => {
        // console.log('getProductsArr res ====', res)
        this.lists_arr = res.rows
        this.total = res.total * 1
        // console.log('total ====', this.total)
      })
    },
    handleSelect (key: string, keyPath: string[], item:any) {
      // console.log('========= handleSelect =========')
      // key === /list/2
      // console.log('key ===', key)
      // console.log('keyPath ===', keyPath)
      // console.log('item ===', item)
      // console.log('this.activeIndex ===', this.activeIndex)
      // console.log('========= end =========')
      if (key !== this.activeIndex) {
        this.activeIndex = key
        goUrl(key)
      }
    },
    goInfo (activity:any) {
      // console.log('goInfo activity ====', activity)
      if (activity.id) {
        const url = '/info/' + this.activeIndex + '/' + this.activePage + '/' + activity.id
        goUrl(url)
      }
    },
    loadList () {
      // console.log('======== loadList ========')
      // if (true) {
      this.getListsArr()
      // }
    },
    handleCurrentChange (val: number) {
      console.log(`current page: ${val}`)
      this.activePage = val
      this.getListsArr()
    }
  }
})
export default class List extends Vue {}
