
import { Options, Vue } from 'vue-class-component'

@Options({
  data () {
    return {
    }
  },
  components: {
  },
  methods: {
  },
  computed: {
  }
})
export default class NotFound extends Vue {}
