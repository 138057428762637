const getters = {
  allState (state:any, opt:any) {
    return () => {
      console.log('state =====', state)
      console.log('opt =====', opt)
      return {
        state,
        opt
      }
    }
  }
}

export default getters
