
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'

@Options({
  data () {
    return {
      banners: []
    }
  },
  components: {
  },
  methods: {
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.home,
      language: (state:any) => state.language
    })
  }
})
export default class Home extends Vue {}
