const axios = require('axios')
axios.defaults.baseURL = 'https://manager.deyuandianli.com/prod-api/api'

export function get (url:string, params:any) {
  return new Promise((resolve, reject) => {
    axios.get(url, params)
      .then(function (response:any) {
        // console.log(response)
        resolve(response.data)
      })
      .catch(function (error:any) {
        // console.log(error)
        reject(error)
      })
  })
}

export function post (url:string, params:any) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(function (response:any) {
        // console.log(response)
        resolve(response.data)
      })
      .catch(function (error:any) {
        // console.log(error)
        reject(error)
      })
  })
}
