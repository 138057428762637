import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '../components/layout/index.vue'
import Home from '../views/home.vue'
import About from '../views/about.vue'
import NotFound from '../views/404.vue'
import List from '../views/list.vue'
import Info from '../views/info.vue'
import Detail from '../views/detail.vue'
import Introduction from '../views/introduction.vue'
import Product from '../views/product.vue'
import Qualify from '../views/qualify.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout,

    // 重定向
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: Home,
        name: 'home',
        meta: {
          title: '首页 hello'
          // icon: "dashboard",
          // affix: true
        }
      }
    ]
  },
  {
    path: '/about',
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: Layout,
    children: [
      {
        path: '/about',
        component: About,
        name: 'about',
        meta: {
          title: '关于'
        }
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // path: '/list',
        path: '/product/:productid',
        component: Product,
        name: 'product',
        meta: {
          title: '产品中心'
        }
      }
    ]
  },
  {
    path: '/list',
    component: Layout,
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // path: '/list',
        path: '/list/:listid',
        component: List,
        name: 'list',
        meta: {
          title: '列表页'
        }
      }
    ]
  },
  {
    path: '/info',
    component: Layout,
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // path: '/list',
        // path: '/info/:listid/:infoid',
        path: '/info/:listid/:page/:infoid',
        component: Info,
        name: 'info',
        meta: {
          title: '详情页'
        }
      }
    ]
  },
  {
    path: '/detail',
    component: Layout,
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // :listid 列表  :page 页面  :detailid  id
        path: '/detail/:listid/:page/:detailid',
        component: Detail,
        name: 'detail',
        meta: {
          title: '详情页'
        }
      }
    ]
  },
  {
    path: '/introduction',
    component: Layout,
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // path: '/list',
        path: '/introduction/:listid/:introductionid',
        component: Introduction,
        name: 'introduction',
        meta: {
          title: '详情页'
        }
      }
    ]
  },
  {
    path: '/qualify',
    component: Layout,
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // path: '/list',
        path: '/qualify/:listid/:qualifyid',
        component: Qualify,
        name: 'qualify',
        meta: {
          title: '资质'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    component: Layout,
    redirect: '/404',
    children: [
      {
        // 使用占位符声明接收的params参数
        // path: '/list/:id/:name', // 使用占位符声明接收的params参数
        // path: '/list',
        path: '/404',
        component: NotFound,
        name: 'notfound',
        meta: {
          title: '无当前页'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
