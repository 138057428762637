
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
import { useRoute } from 'vue-router'
import { goUrl } from '@/utils/index'

@Options({
  data () {
    return {
      activeIndex: '',
      list_id: '',
      qualify_id: '',
      lists_obj: {},
      qualify_obj: {},
      srcList: []
    }
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.qualify
    })
  },
  updated () {
    // console.log('========= updated =========')
    this.qualifyInit()
    // console.log('========= end =========')
  },
  mounted (): void {
    // console.log('========= mounted =========')
    this.qualifyInit()
    // console.log('========= end =========')
  },
  methods: {
    qualifyInit () {
      const route = useRoute()
      // console.log('qualifyInit route ====', route)
      // console.log('qualifyInit route.params ====', route.params)
      // console.log('qualifyInit route.fullPath ====', route.fullPath)
      // console.log('qualifyInit route.query ====', route.query)
      // console.log('qualifyInit this.json ====', this.json)
      if (route.params.listid && route.params.qualifyid && route.params.qualifyid !== this.qualify_id) {
        this.list_id = route.params.listid
        this.qualify_id = route.params.qualifyid
        // console.log('qualifyInit list_id ====', this.list_id)
        // console.log('qualifyInit qualify_id ====', this.qualify_id)
        this.activeIndex = route.fullPath
        this.lists_obj = this.json.listObj[this.list_id]
        this.qualify_obj = this.lists_obj.qualifys[this.qualify_id]
      }
      // console.log('qualifyInit lists_obj ====', this.lists_obj)
      // console.log('qualifyInit qualify_obj ====', this.qualify_obj)
    },
    handleSelect (key: string, keyPath: string[], item:any) {
      // console.log('========= handleSelect =========')
      // key === /list/2
      // console.log('key ===', key)
      // console.log('keyPath ===', keyPath)
      // console.log('item ===', item)
      // console.log('this.activeIndex ===', this.activeIndex)
      if (key !== this.activeIndex) {
        this.activeIndex = key
        goUrl(key)
      }
      // console.log('========= end =========')
    },
    goBack () {
      // console.log('========= goBack =========')
      // const router = useRouter()
      // console.log('useRouter ===', useRouter)
      // console.log('router ===', router)
      // console.log('this.$router ===', this.$router)
      // router.go(-1)
      this.$router.back()
      // console.log('========= end =========')
    },
    viewImage (val: string) {
      this.srcList = []
      this.srcList.push(val)
    }
  }
})
export default class qualify extends Vue {}
