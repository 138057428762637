import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d404e6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-wrap" }
const _hoisted_2 = { class: "menu-wrap" }
const _hoisted_3 = { class: "text-center out-hidden" }
const _hoisted_4 = ["dir"]
const _hoisted_5 = ["dir"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_image, {
      class: "product-banner",
      src: _ctx.imgUrl + _ctx.json.logo
    }, null, 8, ["src"]),
    _createVNode(_component_el_row, { justify: "center" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 16,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, {
              justify: "space-between",
              class: "product-container"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 5 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.json.name), 1),
                      _createVNode(_component_el_menu, {
                        "default-active": _ctx.activeIndex,
                        class: "el-menu-vertical-demo",
                        onSelect: _ctx.handleSelect
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products_menus, (item, ind) => {
                            return (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: ind,
                              index: item.id,
                              route: item.id
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: "out-hidden text-center menu-item",
                                  dir: _ctx.language=='ar' ? 'rtl' : 'ltr'
                                }, _toDisplayString(item.name), 9, _hoisted_4)
                              ]),
                              _: 2
                            }, 1032, ["index", "route"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["default-active", "onSelect"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 18 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, { class: "product-wrap" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products_arr, (item, index) => {
                          return (_openBlock(), _createBlock(_component_el_col, {
                            span: 8,
                            key: index,
                            class: "product-li",
                            onClick: ($event: any) => (_ctx.goInfo(item))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_row, { justify: "center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_image, {
                                    src: item.coverImage
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_el_row, { justify: "center" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    dir: _ctx.language=='ar' ? 'rtl' : 'ltr'
                                  }, _toDisplayString(item.name), 9, _hoisted_5)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    (_ctx.products_arr && _ctx.products_arr.length > 0)
                      ? (_openBlock(), _createBlock(_component_el_row, {
                          key: 0,
                          justify: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_pagination, {
                              layout: "prev, pager, next",
                              "page-size": _ctx.page_size,
                              total: _ctx.total * 1,
                              "current-page": _ctx.activePage,
                              onCurrentChange: _ctx.handleCurrentChange
                            }, null, 8, ["page-size", "total", "current-page", "onCurrentChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}