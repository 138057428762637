import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f105ebd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["dir"]
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "footer-name min-height"
}
const _hoisted_4 = { key: 6 }
const _hoisted_5 = { class: "footer-value min-height" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "footer-name"
}
const _hoisted_8 = { class: "footer-value" }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_Location = _resolveComponent("Location")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Phone = _resolveComponent("Phone")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Promotion = _resolveComponent("Promotion")!
  const _component_el_footer = _resolveComponent("el-footer")!

  return (_openBlock(), _createBlock(_component_el_footer, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, {
        justify: "center",
        class: "footer-wrap"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: 16,
            xs: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_row, {
                align: "middle",
                class: "footer-container"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_row, {
                        align: "middle",
                        justify: "center",
                        class: "img-left"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_image, {
                            src: require('@/assets' + _ctx.json.imgs.left)
                          }, null, 8, ["src"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, {
                    span: 18,
                    class: "footer-content"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.json.list && _ctx.json.list.length * 1 > 0)
                        ? (_openBlock(), _createBlock(_component_el_row, {
                            key: 0,
                            justify: "center",
                            align: "middle"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_col, { span: 2 }),
                              _createVNode(_component_el_col, { span: 22 }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.json.list, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: index,
                                      dir: _ctx.language=='ar' ? 'rtl' : 'ltr'
                                    }, [
                                      (item.path && item.path.indexOf('http') >= 0)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            class: "min-height el-row min-height footer-a footer-nowrap",
                                            href: (item.path),
                                            target: "_blank"
                                          }, [
                                            (item.name || item.icon)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                                  _createVNode(_component_el_row, {
                                                    class: "max-height footer-nowrap text-nowrap",
                                                    align: "middle"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      (item.icon =='Location')
                                                        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_Location)
                                                            ]),
                                                            _: 1
                                                          }))
                                                        : _createCommentVNode("", true),
                                                      (item.icon =='Phone')
                                                        ? (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_Phone)
                                                            ]),
                                                            _: 1
                                                          }))
                                                        : _createCommentVNode("", true),
                                                      (item.icon =='Message')
                                                        ? (_openBlock(), _createBlock(_component_el_icon, { key: 2 }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_Message)
                                                            ]),
                                                            _: 1
                                                          }))
                                                        : _createCommentVNode("", true),
                                                      (item.icon =='Promotion')
                                                        ? (_openBlock(), _createBlock(_component_el_icon, { key: 3 }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_Promotion)
                                                            ]),
                                                            _: 1
                                                          }))
                                                        : _createCommentVNode("", true),
                                                      (item.icon =='police')
                                                        ? (_openBlock(), _createBlock(_component_el_image, {
                                                            key: 4,
                                                            class: "has-s-pd-right",
                                                            src: require('@/assets' + _ctx.json.imgs.police)
                                                          }, null, 8, ["src"]))
                                                        : _createCommentVNode("", true),
                                                      (item.icon =='police2')
                                                        ? (_openBlock(), _createBlock(_component_el_image, {
                                                            key: 5,
                                                            class: "has-s-pd-right",
                                                            src: require('@/assets' + _ctx.json.imgs.police)
                                                          }, null, 8, ["src"]))
                                                        : _createCommentVNode("", true),
                                                      (item.name)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.name), 1))
                                                        : _createCommentVNode("", true)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]))
                                              : _createCommentVNode("", true),
                                            _createElementVNode("div", _hoisted_5, [
                                              _createVNode(_component_el_row, {
                                                class: "max-height",
                                                align: "middle"
                                              }, {
                                                default: _withCtx(() => [
                                                  (typeof item.value == 'string')
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(item.value), 1))
                                                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(item.value, (obj, key) => {
                                                        return (_openBlock(), _createElementBlock("div", {
                                                          class: "has-pd-right",
                                                          key: key
                                                        }, _toDisplayString(obj), 1))
                                                      }), 128))
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ])
                                          ], 8, _hoisted_2))
                                        : (_openBlock(), _createBlock(_component_el_row, {
                                            key: 1,
                                            class: "min-height footer-nowrap",
                                            justify: "start",
                                            onClick: ($event: any) => (_ctx.toLink(item.path))
                                          }, {
                                            default: _withCtx(() => [
                                              (item.name)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                                    _createVNode(_component_el_row, {
                                                      class: "max-height footer-nowrap text-nowrap",
                                                      align: "middle"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        (item.icon =='Location')
                                                          ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_Location)
                                                              ]),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true),
                                                        (item.icon =='Phone')
                                                          ? (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_Phone)
                                                              ]),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true),
                                                        (item.icon =='Message')
                                                          ? (_openBlock(), _createBlock(_component_el_icon, { key: 2 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_Message)
                                                              ]),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true),
                                                        (item.icon =='Promotion')
                                                          ? (_openBlock(), _createBlock(_component_el_icon, { key: 3 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_Promotion)
                                                              ]),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true),
                                                        _createElementVNode("div", null, _toDisplayString(item.name), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]))
                                                : _createCommentVNode("", true),
                                              _createElementVNode("div", _hoisted_8, [
                                                _createVNode(_component_el_row, {
                                                  class: "max-height",
                                                  align: "middle"
                                                }, {
                                                  default: _withCtx(() => [
                                                    (typeof item.value == 'string')
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(item.value), 1))
                                                      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(item.value, (obj, key) => {
                                                          return (_openBlock(), _createElementBlock("div", {
                                                            class: "has-pd-right",
                                                            key: key
                                                          }, _toDisplayString(obj), 1))
                                                        }), 128))
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                    ], 8, _hoisted_1))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}