import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-477a14a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "menu-wrap" }
const _hoisted_3 = ["dir"]
const _hoisted_4 = ["dir"]
const _hoisted_5 = ["dir"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_image, {
      class: "info-banner",
      src: _ctx.imgUrl + _ctx.json.logo
    }, null, 8, ["src"]),
    _createVNode(_component_el_row, { justify: "center" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 16,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, {
              justify: "space-between",
              class: "info-container"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 5 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      (_ctx.menu_name)
                        ? (_openBlock(), _createElementBlock("h3", {
                            key: 0,
                            class: "text-center out-hidden",
                            dir: _ctx.language=='ar' ? 'rtl' : 'ltr'
                          }, _toDisplayString(_ctx.menu_name), 9, _hoisted_3))
                        : _createCommentVNode("", true),
                      _createVNode(_component_el_menu, {
                        "default-active": _ctx.activeIndex,
                        class: "el-menu-vertical-demo",
                        onSelect: _ctx.handleSelect
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products_arr, (item, ind) => {
                            return (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: ind,
                              index: item.id,
                              route: item.id
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_tooltip, {
                                  effect: "dark",
                                  placement: "top-start",
                                  content: item.name
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: "out-hidden text-center menu-item",
                                      dir: _ctx.language=='ar' ? 'rtl' : 'ltr'
                                    }, _toDisplayString(item.name), 9, _hoisted_4)
                                  ]),
                                  _: 2
                                }, 1032, ["content"])
                              ]),
                              _: 2
                            }, 1032, ["index", "route"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["default-active", "onSelect"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 18 }, {
                  default: _withCtx(() => [
                    (_ctx.info_info.content)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "info-content",
                          dir: _ctx.language=='ar' ? 'rtl' : 'ltr'
                        }, [
                          _createElementVNode("div", {
                            class: "info-module",
                            innerHTML: _ctx.info_info.content
                          }, null, 8, _hoisted_6)
                        ], 8, _hoisted_5))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}