
// import { AnyColumn } from 'element-plus/es/components/table-v2/src/common'
// import 'element-plus/theme-chalk/display.css'
import { Options, Vue } from 'vue-class-component'
// import { ref } from 'vue'
import { useStore, mapState } from 'vuex'
import { goUrl } from '@/utils/index'
import { useRouter } from 'vue-router'

@Options({
  // 注入父组件事件
  inject: ['reload'],
  data () {
    const router = useRouter()
    // console.log('router ===', router)
    const activeIndex = router.currentRoute.value && router.currentRoute.value.fullPath ? router.currentRoute.value.fullPath : '/'
    // console.log(router.currentRoute.value)
    // console.log('activeIndex ===', activeIndex)
    return {
      // activeIndex: '/'
      router: router,
      activeIndex: activeIndex
    }
  },
  computed: {
    ...mapState({
      language: (state:any) => state.language,
      json: (state:any) => state.jsons.header,
      imgUrl: (state:any) => state.imgUrl
    })
  },
  components: {
  },
  beforeCreate () {
    this.$store.dispatch('getAllJson', 'ar').then((res:any) => {
      // console.log('res =====', res)
    })
  },
  mounted () {
    // console.log(123)
    // console.log('useStore() ===', useStore())
    // console.log('json ===', this.json)
  },
  methods: {
    handleSelect (key: string, keyPath: string[], item:any) {
      const fullPath = this.router.currentRoute && this.router.currentRoute.fullPath ? this.router.currentRoute.fullPath : '/'
      // console.log('========= handleSelect =========')
      // console.log(key, keyPath, item)
      // console.log('key ===', key)
      // console.log('keyPath ===', keyPath)
      // console.log('item ===', item)
      // console.log('this.activeIndex ===', this.activeIndex)
      // console.log('this.router ===', this.router)
      // console.log('this.router.currentRoute ===', this.router.currentRoute)
      // console.log('fullPath ===', fullPath)
      // console.log('========= end =========')
      if (key !== this.activeIndex || fullPath !== this.activeIndex) {
        // console.log('========= goUrl =========')
        // console.log('========= key !== this.activeIndex =========')
        // console.log(key !== this.activeIndex)
        // console.log('========= fullPath !== this.activeIndex =========')
        // console.log(fullPath !== this.activeIndex)
        this.activeIndex = key
        goUrl(key)
      }
    },
    changeLanguage () {
      // console.log(this.language)
      // console.log(666)
      const str = this.language === 'cn' ? 'ar' : 'cn'
      this.$store.dispatch('changeJson', str).then((res:any) => {
        this.reload()
      })
    },
    // 点击获取参数
    handleCommand (command:any) {
      // console.log('=== handleCommand ===')
      // console.log('command ===', command)
      const path = command.item.path
      // console.log('this.$store ===', this.$store)
      // console.log('this.$store.state ===', this.$store.state)
      // console.log('this.$store.state.header.avatar ===', this.$store.state.header.avatar)
      // console.log('this.$store.getters ===', this.$store.getters)
      // console.log('this.$store.getters.allState ===', this.$store.getters.allState())
      // 引用vuex方法
      // this.$store.commit('SET_TOKEN', 5)
      // this.$store.dispatch('setToken', 5)
      goUrl(path)
    },
    // 注入参数
    composeValue (item:any, index:any) {
      // console.log('=== composeValue ===')
      // console.log('it ===', it)
      // console.log('index ===', index)
      return {
        item: item,
        index: index
      }
    }
  }
})
export default class Header extends Vue {}
