
// import { AnyColumn } from 'element-plus/es/components/table-v2/src/common'
// import 'element-plus/theme-chalk/display.css'
import { Options, Vue } from 'vue-class-component'
import { goUrl } from '@/utils/index'
import { mapState } from 'vuex'

@Options({
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      imgUrl: (state:any) => state.imgUrl,
      json: (state:any) => state.jsons.footer,
      language: (state:any) => state.language
    })
  },
  mounted () {
    // console.log('footer')
  },
  methods: {
    toLink (path:any) {
      console.log(path)
      if (path && path !== '/') {
        goUrl(path)
      }
    }
  }
})
export default class Footer extends Vue {}
