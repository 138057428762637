
import { Options, Vue } from 'vue-class-component'
import { mapState } from 'vuex'
var BMap:any = {}

@Options({
  data () {
    return {
    }
  },
  components: {
  },
  methods: {
    createMap () {
      // console.log(window)
      BMap = (window as any).BMapGL
      // console.log('BMap ====', BMap)
      console.log('json ====', this.json)
      this.$nextTick(() => {
        const mapInfo = this.json.map
        var map = new BMap.Map('container')
        var point = new BMap.Point(mapInfo.coordinateX, mapInfo.coordinateY)
        map.centerAndZoom(point, mapInfo.level)
        map.enableScrollWheelZoom(true)
        var marker = new BMap.Marker(point)
        marker.setTitle('郑州德原电力')
        map.addOverlay(marker)
      })
    }
  },
  mounted () {
    console.log('===== mounted =====')
    this.createMap()
    console.log(this.language)
    setTimeout(() => {
      console.log(this.language)
    }, 2 * 1000)
  },
  computed: {
    ...mapState({
      json: (state:any) => state.jsons.about,
      language: (state:any) => state.language
    })
  }
})
export default class About extends Vue {}
